<form class="video-form" #videoForm="ngForm">
  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_step_name' | translate }}</mat-label>
    <input matInput placeholder="{{ 'step_step_name' | translate }}"
           #title="ngModel" name="title" [(ngModel)]="stepInfo.title" required maxlength="50" whitespace>
    <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_text' | translate }}</mat-label>
    <textarea matInput placeholder="{{ 'step_text' | translate }}"
              #description="ngModel" name="description" [(ngModel)]="stepInfo.description" maxlength="300" rows="3"></textarea>
    <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_300_chars' | translate }}</mat-error>

  </mat-form-field>

  <wdm-file-input [inputType]="'video'" [required]=true [(inputFiles)]="videoFiles" (inputFilesChange)="onFileChanges($event)" (errors)="hasErrors($event)"></wdm-file-input>
  <!--<mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_video' | translate }} {{ 'max_filesize_100mb' | translate }}</mat-label>
    <ngx-mat-file-input #videoInput #fileInput="ngModel" name="fileInput" matInput [(ngModel)]="stepInfo.fileInput"
                        (change)="uploadVideo($event.target.files, stepInfo, videoInput)" placeholder="{{ 'step_choose_file_placeholder' | translate }} {{ 'max_filesize_100mb' | translate }}"
                        accept=".mp4" class="image-input" required></ngx-mat-file-input>
    <mat-icon matSuffix>insert_video</mat-icon>
    <mat-error *ngIf="isMaxSize">{{ 'error_max_filesize_100mb' | translate }}</mat-error>

  </mat-form-field>-->

  <div *ngIf="videoFiles.length == 1 && videoFiles[0].preview != '' && videoFiles[0] != undefined" class='uploadPreview-component img-container'>
    <video id="uploadPreview-video" [src]="videoFiles[0].preview" alt="video" controls></video>
  </div>
</form>
