import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
/*registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdkNjUXtadXBdQWZb'); key antigua */
registerLicense('Mgo+DSMBaFt+QHFqUUdrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQ1xjSn5XdU1iWnZfc3U=;Mgo+DSMBPh8sVXJ1S0d+WFBPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSH9TdUVnWndfc31SR2A=;ORg4AjUWIQA/Gnt2VFhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdkZjXXxWcXdcTmZa;Mjc5NTAwMEAzMjMxMmUzMTJlMzQzMU50blRVc0YzemlKZmx2dDdIdmZzekpLOE5RelRMbTFTLzk5dlB1eGlQL0k9;Mjc5NTAwMUAzMjMxMmUzMTJlMzQzMVNsajJBK1l3ZzdEc0tKbmw4b2pGcHZlNmhIQmJ5c0ltVFBOMUF4aHA5bVE9;NRAiBiAaIQQuGjN/V0d+XU9Ad1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Sd0VgWXtdeHVRRmNfVA==;Mjc5NTAwM0AzMjMxMmUzMTJlMzQzMU4zb1lKVDJ5bGZRbi9GVnZuUlQ1Z2R6R2pJSVNGQ0VRTCtSYlQ5OThzK0k9;Mjc5NTAwNEAzMjMxMmUzMTJlMzQzMUpQVDNwdHhtS0QyVFRPbnB4N3A1U1ZJRWZMKzJMeXM3czE2eHZjSk1aT0U9;Mgo+DSMBMAY9C3t2VFhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdkZjXXxWcXBVT2BZ;Mjc5NTAwNkAzMjMxMmUzMTJlMzQzMWhUa3Q1OWlMTE50TE5kY010cER5YWN2TmZWaGJqazVZVENFRnR1b2g1czA9;Mjc5NTAwN0AzMjMxMmUzMTJlMzQzMVIvTXk2TGxmd2tkcnpnby9XTlJ3Qm9UczN6cksrcHFhTWEyUk9TZC80Ymc9;Mjc5NTAwOEAzMjMxMmUzMTJlMzQzMU4zb1lKVDJ5bGZRbi9GVnZuUlQ1Z2R6R2pJSVNGQ0VRTCtSYlQ5OThzK0k9');

//import 'hammerjs';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () { };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
