<form class="PDF-form" #PDFForm="ngForm">
  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_step_name' | translate }}</mat-label>
    <input matInput placeholder="{{ 'step_step_name' | translate }}"
           #title="ngModel" name="title" [(ngModel)]="stepInfo.title" required maxlength="50" whitespace>
    <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_text' | translate }}</mat-label>
    <textarea matInput placeholder="{{ 'step_text' | translate }}"
              #description="ngModel" name="description" [(ngModel)]="stepInfo.description" maxlength="300" rows="3"></textarea>
    <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_300_chars' | translate }}</mat-error>
  </mat-form-field>

  <div class="pdf-input-area">
    <wdm-file-input [inputType]="'pdf'" [required]=true [(inputFiles)]="pdfFiles" (inputFilesChange)="onFileChanges($event)" (errors)="hasErrors($event)"></wdm-file-input>

    <mat-form-field appearance="fill" class="step-form-input" title="{{'step-page-value_title' | translate }}">
      <mat-label>{{ 'step-page-value' | translate }}</mat-label>
      <input matInput #pageNumber name="pageNumber" type="number" class="page-number" [(ngModel)]="stepInfo.content" min="0" />
    </mat-form-field>
  </div>

  <div *ngIf="pdfFiles.length == 1 && pdfFiles[0].preview != '' && pdfFiles[0].preview != undefined" class='uploadPreview-component embed-pdf'>
    <pdf-viewer [src]="pdfFiles[0].preview" [render-text]="true" [original-size]="false" [page]="stepInfo.content" style="height: calc(100vh - 650px)"></pdf-viewer>

    <!-- En principio quito la funcionalidad de que al cambiar de página se actualice el campo página, le veo tantas ventajas como inconvenientes
    Al cargar a un documento ya guardado salta a la página uno siempre cambiando el valor (Aunque no debería)
    y si pasean por el documento pueden cambiar la página sin ser conscientes.
    De este modo al cargar lo sigue llevando a la primera página pero no cambia el valor en el campo.
    Probamos un tiempo así y si no revisamos: [(page)]="stepInfo.content"-->
  </div>

</form>
