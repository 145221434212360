import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Link, StepDto } from '../../../../models/StepDTO';
import { StepType } from '../../../../models/StepTypes';
import { TranslatePipe } from '../../../../pipes/translate.pipe';
import { AuthService } from '../../../../services/auth.service';
import { StepService } from '../../../../services/step.service';
import { whitespace } from '../../../../Validators/text.validator';
import { AlertDialogComponent } from '../../../dialogs/alert/alert.component';


interface selectChildOption {
  childValue: number;
  childText: string;
  isFinal: boolean;
  nameId: number;
  stepTypeIconUrl: string;
}

@Component({
  selector: 'wdm-link-form',
  templateUrl: './link-form.component.html',
  styleUrls: ['../../../../styles/components/steps.scss', 'link-form.component.scss'],
  providers: [TranslatePipe]
})

export class LinkFormComponent implements OnInit {

  @Input() stepInfo: StepDto;
  @Input() allSteps: StepDto[];
  @Output() errors: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @ViewChildren('answerInput') answerInput: QueryList<ElementRef>;
  @ViewChildren('option') public dropDownSelection: QueryList<DropDownListComponent>;

  answersForm: FormGroup;
  childs: FormArray;
  childsOptions: selectChildOption[] = [];
  childSelectedValue: string;
  stepsToDelete: StepDto[];
  stepType: typeof StepType = StepType;
  currentValue: FormArray;
  numberOperations: string[] = ['=', '<', '>', '<=', '>=', '-'];
  public fields: Object = { text: "childText", value: "childValue" };

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private auth: AuthService, private translateService: TranslatePipe, public stepService: StepService) { }

  ngOnInit(): void {
    this.loadSteps();
    this.createSelectOptions();
    this.stepsToDelete = [];
  }

  ngDoCheck(): void {
    if (this.answersForm.status === 'INVALID') {
      this.errors.emit(true);
    } else {
      this.errors.emit(false);
      this.stepInfo.state = "OK";
    }
  }


  onMatSelectOpen(form: AbstractControl) {
    this.currentValue = form.value.childs;
  }

  forceValidation() {
    this.childs.markAllAsTouched();
  }

  loadSteps(): void {
    this.answersForm = this.formBuilder.group({
      childs: this.formBuilder.array([])
    });

    if (!this.stepService.isMultipathStep(this.stepInfo)) {
      this.stepInfo.links.forEach(child => {
        this.addItem(child);
      });
      return;
    }
      
    if (this.stepInfo.stepTypeId == StepType.BinaryQuestion && this.stepInfo.links.length == 1) {  //binary first time
      var childId = -2;
      if (!this.stepInfo.links[0].isFinal) {
        childId = this.stepInfo.links[0].id;
        var childStep = this.allSteps.find(s => s.stepId == childId);
        childStep.parents.push(this.stepInfo.stepId);
      }
      this.addAnswerItem({ id: childId, text: this.translateService.transform("generic_text_yes"), stepTitle: '', order: 0, isFinal: false });
      this.addAnswerItem({ id: childId, text: this.translateService.transform("generic_text_no"), stepTitle: '', order: 0, isFinal: false });
      return;
    }

    if (this.stepInfo.stepTypeId == StepType.Numeric) {
     
      this.stepInfo.links.forEach(link => {
        var data = JSON.parse(link.text);
        if (data != null && Object.keys(data).length > 0) {
          this.addRangeItem({ id: link.id, text: data, stepTitle: '', order: link.order, isFinal: link.isFinal });
        } else {
          link.order = this.stepInfo.links.length + 1;
          this.addItem(link);
        }

      });
      return;
    }

    if (this.stepInfo.stepTypeId == StepType.MultiAnswer && this.stepInfo.title == '' && this.stepInfo.links.length == 1 && this.stepInfo.links[0].isFinal) { //multianswer first time
      this.addAnswerItem({ id: -2, text: '', stepTitle: '', order: 0, isFinal: false });
      return;
    }
      
    this.stepInfo.links.forEach(child => {
      this.addAnswerItem(child);
    });

  }

  addItem(child: Link): void {
    this.childs = this.answersForm.get('childs') as FormArray;
    var childId = this.formBuilder.control(child.id, [Validators.required]);
    var childOrder = this.formBuilder.control(child.order);

    var item = this.formBuilder.group({ 'text': null, 'id': childId, 'order': childOrder, 'option': childId });
 
    this.childs.insert(child.order, item);
  }

  addAnswerItem(child: Link): void {
    this.childs = this.answersForm.get('childs') as FormArray;
    var answer = this.formBuilder.control(child.text, [Validators.required, Validators.maxLength(100), whitespace()]);
    var childId = this.formBuilder.control(child.id, [Validators.required]);
    var childOrder = this.formBuilder.control(this.childs.length + 1);

    var item = this.formBuilder.group({ 'text': answer, 'id': childId, 'order': childOrder, 'option': childId });

    this.childs.push(item);
  }

  addRangeItem(child: Link): void {
    this.childs = this.answersForm.get('childs') as FormArray;

    var operation = this.formBuilder.control(child.text == null ? null : child.text.operation, [Validators.required]);
    var sinceNumber = this.formBuilder.control(child.text == null ? null : child.text.sinceNumber, [Validators.required]);
    var forNumber = this.formBuilder.control(child.text == null ? null : child.text.forNumber, [Validators.required]); 
    var childId = this.formBuilder.control(child.id, [Validators.required]);
    var childOrder = this.formBuilder.control(child.order == 0 ?? this.childs.length - 1);

    if (child.text != null) {
      if (child.text.operation == '-') {
        sinceNumber.addValidators(Validators.max(child.text.forNumber));
        forNumber.addValidators(Validators.min(child.text.sinceNumber));
      } else {
        forNumber.disable();
      }
    }

    var item = this.formBuilder.group({ 'operation': operation, 'sinceNumber': sinceNumber, 'forNumber': forNumber, 'id': childId, 'order': childOrder, 'option': childId });
    this.childs.insert(child.order, item); //el último siempre es el dropdown del paso por defecto

    operation.valueChanges.subscribe(value => {
      if (value != '-') forNumber.disable();
      else forNumber.enable();
    });

    sinceNumber.valueChanges.subscribe(value => {
      forNumber.clearValidators();
      forNumber.addValidators(Validators.required);
      if (value != null && operation.value == '-') forNumber.addValidators(Validators.min(value));
      forNumber.updateValueAndValidity({ emitEvent: false });
    });

    forNumber.valueChanges.subscribe(value => {
      sinceNumber.clearValidators();
      sinceNumber.addValidators(Validators.required);
      if (value != null && operation.value == '-') sinceNumber.addValidators(Validators.max(value));
      sinceNumber.updateValueAndValidity({ emitEvent: false });
    });
  }

  createSelectOptions() {
    this.childsOptions = [];

    var newSteps = this.allSteps.filter(s => this.stepInfo.links.find(l => l.id == s.stepId) && s.stepTypeId == StepType.New).length;

    this.allSteps.forEach(step => {
      if (step.stepTypeId == StepType.Init) return;
      if (step.stepTypeId == StepType.End) return;
      if (step.stepId == this.stepInfo.stepId) return;
      let nameId = 0;
      if (this.childsOptions.find(c => c.childValue == step.stepId) == null) {
        if (step.stepTypeId == StepType.New) {
          var stringNum = step.title.replace(this.translateService.transform("new_step_name"), '').trim();
          if (stringNum != "") nameId = Number.parseInt(stringNum);
        }

        const option: selectChildOption = { childValue: step.stepId, childText: step.stepId + ". " + step.title, isFinal: false, nameId: nameId, stepTypeIconUrl: this.getShape(step.stepTypeId) };
        this.childsOptions.push(option);
      }
    });

    if (this.stepService.isMultipathStep(this.stepInfo)) {

      if ((this.childs != undefined && newSteps < this.childs.length) || newSteps == 0) {
        var totalNews = this.childs.length - newSteps;
        var nameId = this.childsOptions.length > 0 ? Math.max(...this.childsOptions.map(o => o.nameId)) + 1 : 1;
        for (var i = 1; i <= totalNews; i++) {
          var title = this.translateService.transform("new_step_name");
          if (this.childsOptions.find(o => o.nameId == nameId)) nameId++;
          title += " " + nameId;
          const newOption: selectChildOption = { childValue: (-1 - i), childText: title, isFinal: false, nameId: nameId, stepTypeIconUrl: this.getShape(this.stepType.New)};
          this.childsOptions.push(newOption);
        }
      }
    }

    if (!this.childsOptions.some(o => o.isFinal)) {
      var titleFinal = this.translateService.transform("end_step_name");
      var finalOption: selectChildOption = { childValue: -1, childText: titleFinal, isFinal: true, nameId: 0, stepTypeIconUrl: this.getShape(this.stepType.End) };

      var finalStep = this.allSteps.find(s => s.stepTypeId == StepType.End);
      if (finalStep != undefined) finalOption.childValue = finalStep.stepId;

      this.childsOptions.push(finalOption);
    }

    //add this step
    var tempTitle = this.translateService.transform("this_step_title");
    const option: selectChildOption = { childValue: this.stepInfo.stepId, childText: tempTitle, isFinal: false, nameId: 0, stepTypeIconUrl: this.getShape(this.stepInfo.stepTypeId) };
    this.childsOptions.push(option);
  }

  getShape(stepTypeId) {
    return this.stepService.getStepShapeRoute(stepTypeId);
  }

  removeItem(index: number): void {
    if (this.childs.length == 1) return; // hay que dejar una por lo menos

    var child = this.childs.at(index);
    var childStep = this.allSteps.find(c => c.stepId == child.value.id);
    if (childStep == undefined) { this.childs.removeAt(index); return; }

    var oldLinks = this.stepInfo.links;
    var childStepOldParents = JSON.parse(JSON.stringify(childStep.parents)); // copy value, not reference

    this.childs.removeAt(index);
    this.updateLinks(childStep);
    this.stepService.parentsChecked = new Map();
    var isOrphan = this.stepService.isOrphan(childStep, this.allSteps);

    if (!isOrphan) {
      console.log("removeItem", childStep);
      this.stepService.modifyStep(childStep);
      this.deleteSteps([]);
      return;
    }

    if (childStep.stepTypeId == StepType.End) {
      this.deleteStep(childStep);
      return;
    }

    if (this.auth.isAuthenticated()) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: 'auto',
        data: {
          message: 'dialog_delete_answer_header',
          title: 'AreYouSure',
          type: 'QUESTION',
          buttons: 'YESNO'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteStep(childStep);
        } else {
          //restore stepInfo (opened step) and deletedStep (changed link). Changed in updateLinks
          this.stepInfo.links = oldLinks;
          this.allSteps.find(s => s.stepId == this.stepInfo.stepId).links = this.stepInfo.links;
          childStep.parents = childStepOldParents;
          this.childs.insert(index, child);
        }
      });
    }
  }

  onLinkChange(event, selectedIndex: number) { 
    var oldLinks = this.stepInfo.links;
    var deletedStep = null;
    let deletedStepOldParents = null;

    if (this.childs instanceof FormArray) {
      var currentValue = this.currentValue[selectedIndex];
      deletedStep = this.allSteps.find(s => s.stepId == currentValue.id); //self loop, or child loop

      if (deletedStep != undefined) {
        deletedStepOldParents = JSON.parse(JSON.stringify(deletedStep.parents)); // copy value, not reference
      }
    }

    this.updateLinks(deletedStep);

    var stepSelected = this.allSteps.find(s => s.stepId == this.dropDownSelection.get(selectedIndex).value);
    if (stepSelected != undefined) { // } && stepSelected.parents.findIndex(p => p == this.stepInfo.stepId) == -1) {
      stepSelected.parents.push(this.stepInfo.stepId);
      this.stepService.modifyStep(stepSelected);
    } 
    var index = this.stepsToDelete.indexOf(stepSelected);
    if (index > -1) this.stepsToDelete.splice(index, 1);

    this.stepService.emitDeleteAnswer(this.stepsToDelete);

    let endLinks = oldLinks.filter(o1 => !this.stepInfo.links.some(o2 => o1.id === o2.id) && o1.isFinal);
    if (endLinks.length > 1) {
      this.deleteSteps(endLinks);
      return;
    }

    if (deletedStep == null) return;

    this.deleteOrphans(deletedStep, oldLinks, deletedStepOldParents, stepSelected);
  }

  updateLinks(deletedStep: StepDto) {
    if (this.childs instanceof FormArray) {
      this.stepInfo.links = [];
      for (let child of this.childs.value) {
        let selectedOption = this.childsOptions.find(o => o.childValue == child.option);
        if (selectedOption == undefined) continue;
            
        this.stepInfo.links.push({ id: selectedOption.childValue, text: this.getLinkText(child), stepTitle: selectedOption.childText, order: child.order, isFinal: selectedOption.isFinal });
      }

      if (deletedStep != null) {
        var index = deletedStep.parents.findIndex(p => p == this.stepInfo.stepId); 
        if (index > -1) {
          deletedStep.parents.splice(index, 1);
          this.stepService.modifyStep(deletedStep);
        } 
      }

      let step = this.allSteps.find(s => s.id == this.stepInfo.id);
      step.links = this.stepInfo.links;
      step.parents = this.stepInfo.parents;
    }
  }

  deleteSteps(linksToDeteleSteps: Link[]): void {
    linksToDeteleSteps.forEach(l => {
      var toDel = this.allSteps.find(s => s.stepId == l.id);
      if (toDel != null) {
        var index = this.stepsToDelete.indexOf(toDel);
        if (index == -1) this.stepsToDelete.push(toDel);
      }
    });

    this.stepService.emitDeleteAnswer(this.stepsToDelete);
  }

  deleteStep(step: StepDto): void {
    var indexDelete = this.stepsToDelete.indexOf(step);
    if (indexDelete == -1) this.stepsToDelete.push(step);

    this.updateLinks(null);

    this.stepService.emitDeleteAnswer(this.stepsToDelete);
  }

  deleteOrphans(deletedStep: StepDto, oldLinks: Link[], deletedStepOldParents: number[], selectedStep: StepDto) {
    this.stepService.parentsChecked = new Map();
    var isOrphan = this.stepService.isOrphan(deletedStep, this.allSteps);

    if (isOrphan) {

      if (deletedStep.stepTypeId == StepType.End) {
        let orphansLinks = oldLinks.find(l => l.id == deletedStep.stepId);
        this.deleteSteps([orphansLinks]);
        return;
      }

      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: 'auto',
        data: {
          message: 'orphan_step_delete_warning',
          title: 'AreYouSure',
          type: 'QUESTION',
          buttons: 'YESNO'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let orphansLinks = oldLinks.find(l => l.id == deletedStep.stepId);
          this.deleteSteps([orphansLinks]);
        } else {
          //restore stepInfo (opened step) and deletedStep (changed link). Changed in updateLinks
          this.stepInfo.links = oldLinks;
          this.allSteps.find(s => s.stepId == this.stepInfo.stepId).links = this.stepInfo.links;
          deletedStep.parents = deletedStepOldParents;

          var index = selectedStep.parents.findIndex(p => p == this.stepInfo.stepId);
          if (index > -1) selectedStep.parents.splice(index, 1);
          this.stepService.undoEdit(selectedStep);

          this.loadSteps();
          this.deleteSteps([]);
        }
      });
    }
  }

  private getLinkText(child): string {
    var text = null;
    if (child.text != '') text = child.text;
    if (this.stepInfo.stepTypeId == StepType.Numeric && child.operation != undefined) text = JSON.stringify({ 'operation': child.operation, 'sinceNumber': child.sinceNumber, 'forNumber': child.forNumber });

    return text;
  }
}
