<wdm-header-root></wdm-header-root>
<loading-component [loading]="!dataSource"></loading-component>

<div class="filters-and-table">
  <div class="wf-report-filters">
    <mat-form-field class="mat-form-field">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'common_placeholders_filter' | translate }}">
    </mat-form-field>
    <mat-form-field class="mat-form-field">
      <mat-label>{{ 'common_headers_startDate' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker" (click)="picker.open()" [formGroup]="filterForm">
        <input matStartDate placeholder="{{ 'common_startDate' | translate }}" formControlName="startDate_startDate" (dateChange)="applyDateFilter('startDate')">
        <input matEndDate placeholder="{{ 'common_endDate' | translate }}" formControlName="endDate_startDate" (dateChange)="applyDateFilter('startDate')">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <div class="mat-elevation-z3">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort style="width: 100%">

      <!-- devName Column -->
      <ng-container matColumnDef="technicianName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_tecname' | translate }} </th>
        <td mat-cell *matCellDef="let row">{{ row.technicianName }}</td>
      </ng-container>

      <!-- wflowname Column -->
      <ng-container matColumnDef="workflowName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_wflowname' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.workflowName}} </td>
      </ng-container>

      <!-- tag Column -->
      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  class="tagsColumn"> {{ 'common_headers_tag' | translate }} </th>
        <td mat-cell *matCellDef="let row" [title]="row.tags !== undefined && row.tags !== null ? row.tags.toString() : 'N/A'"  class="tagsColumn"> {{ row.tags }} </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_startDate' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.startDate | date:'YYYY/MM/dd HH:mm:ss a'}}</td>
      </ng-container>

      <!-- wflowtime Column -->
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'common_headers_time' | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{row.duration}} </td>
      </ng-container>

      <!-- Expand Images Column -->
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.multimediaSteps > 0" title="{{ 'common_tooltips_viewimages' | translate }}"
               class="images-button" [class.images-button-expanded]="expandedElement === row"
               mat-button (click)='expandedElement = expandedElement === row ? null : row; loadImages(row.id, row.multimediaSteps, $event)'></div>
        </td>
      </ng-container>

      <!-- Actions Area -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'common_headers_reports' | translate }} </th>
        <td mat-cell *matCellDef="let row" class="padding-top-4">
          <!--<button class="icon-btn" mat-button (click)='generatePdf(row, $event)' title="{{ 'generate_pdf' | translate }}">
            <div class="report-button generate-report"></div>
          </button>-->
          <a [routerLink]="['report/', row.id]" target="_blank">
            <button class="icon-btn" mat-button id="openReport" title="{{ 'open_report' | translate }}">
              <div class="report-button open-report"></div>
            </button>
          </a>
          <button class="icon-btn" mat-button (click)='generateCsv(row, $event)' title="{{ 'generate_csv' | translate }}">
            <div class="report-button generate-csv"></div>
          </button>
          <button class="icon-btn" mat-button (click)='generateTranscriptionFile(row, $event)' [disabled]="!row.audioComments" title="{{ 'generate_transcriptions' | translate }}">
            <div class="report-button generate-transcriptions" [class.disabled]="!row.audioComments"></div>
          </button>
        </td>
      </ng-container>

      <!-- Expand Images Area -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="element == expandedElement && element.multimediaSteps > 0 ? 'expanded' : 'collapsed'">
            <!--<div class="element-description">-->
            <ng-container *ngTemplateOutlet="imagesTemplate"></ng-container>
            <!--</div>-->
          </div>
        </td>
      </ng-container>

      <!-- Table -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row" [class.pointer]="element.generated"
          [class.rowcolordatesdiff]="element.lastworkflowDeviceMdate != element.lastworkflowCurrentMdate"
          [class.expanded-row]="expandedElement === element" [class.hvr-grow-shadow]="element.generated"></tr>

      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

    </table>

    <!-- Paginator -->
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 25]"></mat-paginator>

  </div>




  <!-- Expand Images Template -->
  <ng-template #imagesTemplate>
    <div *ngIf="!images && !videos && !audios">
      <div style="text-align: center"><i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i></div>
    </div>

    <div class="table">

      <div class="media-thumbnail-a" *ngFor="let image of images">

        <a (click)="openImage(image.name, lastReportId)">
          <div class="media-thumbnail-box">
            <img [src]="image.path" class="media-thumbnail" />
          </div>
          <div class="media-type-hover">
            <i class="fa fa-search-plus hvr-grow" aria-hidden="true"></i>
          </div>
        </a>

      </div>

      <div class="media-thumbnail-a" *ngFor="let video of videos">
        <a (click)="openVideo(video.name, lastReportId)" target="_blank">
          <div class="media-thumbnail-box">
            <img [src]="video.path" class="media-thumbnail" />
          </div>
          <div class="media-type-hover">
            <i class="fa fa-play-circle hvr-grow" aria-hidden="true"></i>
          </div>
        </a>

      </div>

      <div class="media-thumbnail-a" *ngFor="let audio of audios">
        <a (click)="openAudio(audio.name, lastReportId)" target="_blank" style="text-decoration:none">
          <div class="media-thumbnail-box">
            <img src="./assets/img/reports/audio-comment-back.svg" class="media-thumbnail" />
          </div>
          <div class="media-type-hover">
            <span class="text" title="{{ audio.name }}">{{ audio.name }}</span>
          </div>
        </a>

      </div>

    </div>


  </ng-template>

</div>
<loading-component [loading]="loading"></loading-component>
<wdm-alert #alertComponent></wdm-alert>
