<loading-component [loading]="loading"></loading-component>
<wdm-alert #alertComponentReports></wdm-alert>

<div *ngIf="!hasErrors">
    <mat-toolbar class="print-toolbar">
        <span *ngIf="report && report.workflowName">{{report.workflowName}}  ( {{report.technician}} - {{report.startDate  | date:'MM/dd/yyyy HH:mm:ss a'}})</span>
        <button mat-raised-button cdkFocusInitial (click)="printReport()">{{'report_print' | translate}} <i class="fa fa-print" aria-hidden="true"></i></button>
    </mat-toolbar>
    <div class="paper">
        <mat-divider></mat-divider>

        <header *ngIf="report && (report.company!='' || report.logo!='' || report.location!='' || report.team!='' || report.plant!='' || report.freeText!='')">
            <div class="report-metadata">
                <div class="report-metadata-logo">
                    <div>
                        <img *ngIf="report.logo" class="logo" src="{{report.logo}}" />
                    </div>
                    <div *ngIf="report && (report.company!='' || report.location!='' || report.team!='' || report.plant!='')">
                        <div *ngIf="report.company" class="company"> {{report.company}} </div>
                        <div *ngIf="report.location" class="data"> {{report.location}} </div>
                        <div *ngIf="report.team" class="data"> <b>{{'report_team' | translate}}: </b>{{report.team}} </div>
                        <div *ngIf="report.plant" class="data"> <b>{{'report_plant' | translate}}:</b>  {{report.plant}} </div>
                    </div>
                </div>
            </div>
            <p *ngIf="report.freeText" class="other">{{report.freeText}}</p>
        </header>


        <div *ngIf="report" class="header">
            <div class='report-header'>
                <div *ngIf="report.workflowName" class='report-name'>
                    <h3>{{report.workflowName}}</h3>
                </div>
            </div>

            <div class="report-info">
                <ul>
                    <li>
                        <b>{{'report_technician' | translate}}: </b>{{report.technician}}
                    </li>
                    <!--<li>
                      <b>{{'report_device_uid' | translate}}: </b>{{report.uid}}
                    </li>-->
                    <li>
                        <b>{{'report_start_date' | translate}}: </b> {{report.startDate  | date:'YYYY/MM/dd HH:mm:ss a'}}
                    </li>
                    <li>
                        <b>{{'report_end_date' | translate}}: </b> {{report.finishDate  | date:'YYYY/MM/dd HH:mm:ss a'}}
                    </li>
                    <li>
                        <b>{{'report_duration' | translate}}: </b>{{getDuration(report.duration)}}
                    </li>
                </ul>
            </div>
        </div>


        <div *ngIf="report && report.stepsResults">
            <div *ngFor="let step of report.stepsResults" class="step-holder">
                <div class="paso">
                    <div class="step-header">
                        <img [src]="getShape(step.stepTypeId)" class="step-icon" title="{{ getName(step.stepTypeId) | translate}}" />
                        <div class="title">
                            <h3 class="icon-title">{{step.stepTitle}}</h3>
                            <div class="subtitle">{{step.technician}} - {{step.startDate  | date:'YYYY/MM/dd HH:mm:ss a'}} - {{step.finishDate  | date:'YYYY/MM/dd HH:mm:ss a'}} - {{getDuration(step.duration)}}</div>
                        </div>

                        <div *ngIf="step.skipped" class="skipItem-area">
                            <mat-checkbox class="skipItem" [checked]="step.skipped" [disabled]="true"> </mat-checkbox>
                            <p class="check-label">{{'report_step_skipped' | translate}}</p>
                        </div>
                    </div>

                    <div *ngIf="!step.skipped">
                        <div *ngIf="step.stepTypeId == stepType.Capture">
                            <wdm-capture-report [stepinfo]='step'></wdm-capture-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.QrCode || step.stepTypeId == stepType.Meter">
                            <wdm-ia-report [stepinfo]='step' [reportId]="idReport"></wdm-ia-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.BinaryQuestion">
                            <wdm-question-yesno-report [stepinfo]='step'></wdm-question-yesno-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.MultiAnswer">
                            <wdm-question-multi-report [stepinfo]='step'></wdm-question-multi-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.FreeAnswer || step.stepTypeId == stepType.Numeric">
                            <wdm-question-free-report [stepinfo]='step'></wdm-question-free-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.Checklist">
                            <wdm-checklist-report [stepinfo]='step'></wdm-checklist-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.RecordVideo">
                            <wdm-video-report [stepinfo]='step'></wdm-video-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.Date">
                            <wdm-date-report [stepinfo]='step'></wdm-date-report>
                        </div>
                        <div *ngIf="step.stepTypeId == stepType.Tag">
                            <wdm-tag-report [stepinfo]='step'></wdm-tag-report>
                        </div>
                    </div>


                    <wdm-comment *ngIf="step.stepComment" [reportId]="idReport" [stepComment]="step.stepComment"></wdm-comment>

                </div>
            </div>
        </div>

        <div class='report-footer'>
            <h1 class='app-name'>{{appName}}</h1>
        </div>

    </div>
</div>






