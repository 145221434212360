<form class="text-form" #textForm="ngForm">
  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_step_name' | translate }}</mat-label>
    <input matInput placeholder="{{ 'step_step_name' | translate }}" #title="ngModel" name="title"
           [(ngModel)]="stepInfo.title" required maxlength="50" whitespace>
    <mat-error *ngIf="title.invalid && title.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.maxlength">{{ 'error_max_50_chars' | translate }}</mat-error>
    <mat-error *ngIf="title.invalid && title.errors.whitespace && !title.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="step-form-input">
    <mat-label>{{ 'step_text' | translate }}</mat-label>
    <textarea matInput placeholder="{{ 'step_text' | translate }}" #description="ngModel" name="description"
    [(ngModel)]="stepInfo.description" required minlength="10" maxlength="5000" whitespace></textarea>
    <mat-error *ngIf="description.invalid && description.errors.maxlength">{{ 'error_max_5000_chars' | translate }}</mat-error>
    <mat-error *ngIf="description.invalid && description.errors.minlength">{{ 'error_min_10_chars' | translate }}</mat-error>
    <mat-error *ngIf="description.invalid && description.errors.required">{{ 'error_field_required' | translate }}</mat-error>
    <mat-error *ngIf="description.invalid && description.errors.whitespace && !description.errors.required">{{ 'not_only_whitespaces' | translate }}</mat-error>
  </mat-form-field>

  <!-- <button *ngIf="name.invalid || text.invalid || image.invalid" class="icon-btn btn-primary" mat-button><i class="fa fa-check" aria-hidden="true"></i></button>
  <button *ngIf="name.valid && text.valid && image.valid" class="icon-btn btn-primary" mat-button (click)='saveChanges(stepInfo)' [mat-dialog-close]="true"><i class="fa fa-check" aria-hidden="true"></i></button>
  <button class="icon-btn" mat-button (click)='deleteStep()' [mat-dialog-close]="true"><i class="fa fa-trash" aria-hidden="true"></i></button>
  <button class="icon-btn" mat-button [mat-dialog-close]="false"><i class="fa fa-times" aria-hidden="true"></i></button> -->
</form>
